import React from 'react';
import type { PageProps } from "gatsby"
import { Container, Footer, Section } from './styles/layout.styled'
import { StaticImage } from "gatsby-plugin-image"
import { Logo } from './styles/nav.styled';
import { defaultTheme } from './themes';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './styles/global';
import { Paragraph, Title } from './styles/typography';

const Layout: React.FC<PageProps> = ({ children }) => {
    return (
        <ThemeProvider theme={defaultTheme}>
            <GlobalStyles />
            <header>
                <Section>
                    <Container>
                        <Logo>
                            <StaticImage src="../static/barbell-logo-white.svg" alt="Barbell Gym logo" />
                        </Logo>
                    </Container>
                </Section>
            </header>
            <Section>
                <main>{children}</main>
            </Section>
            <Section>
                <Container>
                    <Title color="light">Všechno OK? Tohle je tvůj první krok</Title>
                    <Paragraph color="light"><strong>Ondřej Semík</strong><br />
                        <a href="tel:+420605322672">+420 605 322 672</a><br />
                        <a href="mailto:barbellgympraha@email.cz" target="_blank" rel="noreferrer">barbellgympraha@email.cz</a><br />
                        Holandská 1019/50, Praha 10
                    </Paragraph>

                </Container>
            </Section>
            <Section>
                <Container>
                    <Footer>
                        <p>© {new Date().getFullYear()} | Work in Progress | 🧠💪💖 <a href="https://honzapav.cz" target="_blank" >Honza Páv</a></p>
                    </Footer>
                </Container>
            </Section>

        </ThemeProvider>
    );
};

export default Layout;