import styled from 'styled-components';

export const Section = styled.section`
  width: 940px;
  max-width: 100%;
  padding: 40px 20px;
  margin: 0 auto;
`;

export const Container = styled.div`
  width: 940px;
  max-width: 100%;
  padding: 20px 0px;
  margin: 0 auto;
`;

export const Footer = styled.footer`
  margin: 0 auto;
  
  & p, a {
    color: ${(props) => props.theme.colors.gray};
    text-decoration: none;
  };

  & a:hover {
    border-bottom: 2px dotted;
  }
`;

export const Hero = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 40px 40px;
  margin: 0 auto;

  & p {
    max-width: 500px;
  }
`;

export const TrainingsContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 20px;
`;
