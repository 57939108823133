import { propTypes } from "gatsby-plugin-image/dist/src/components/gatsby-image.server";
import styled from "styled-components";

export const Display = styled.h1`
    font-size: 60px;
    text-align: ${({ center }) => (center ? "center" : "left")};
    color: ${(props) =>
        props.color === 'light'
            ? ({ theme }) => theme.colors.gold
            : ({ theme }) => theme.colors.black};
    font-weight: 900;
    line-height: 80px;
`;
export const Headline = styled.h1`
    font-size: 48px;
    text-align: ${({ center }) => (center ? "center" : "left")};
    font-weight: 900;
    color: ${(props) =>
        props.color === 'light'
            ? ({ theme }) => theme.colors.gold
            : ({ theme }) => theme.colors.black};
`;
export const Title = styled.h2`
    font-size: 36px;
    text-align: ${({ center }) => (center ? "center" : "left")};
    font-weight: 900;
    color: ${(props) =>
        props.color === 'light'
            ? ({ theme }) => theme.colors.gold
            : ({ theme }) => theme.colors.black};
`;

// Subheading-1
// subheading-2

export const Paragraph = styled.p`
    color: ${(props) =>
        props.color === 'light'
            ? ({ theme }) => theme.colors.gold
            : ({ theme }) => theme.colors.black};
    & a {
        text-decoration: none;
        color: ${(props) =>
        props.color === 'light'
            ? ({ theme }) => theme.colors.gold
            : ({ theme }) => theme.colors.black};
    }
    & a:hover {
        border-bottom: 2px dotted;
    }
`;
