import { DefaultTheme } from 'styled-components';

const defaultTheme: DefaultTheme = {
    colors: {
        gold: '#FFD700',
        black: '#000000',
        white: '#FFFFFF',
        red: '#FF0000',
        gray: 'grey',
    },
};

export { defaultTheme };
